<template>
        <div class="mobileContainer">
            <div class="bodyContainer" >
                    <div style="display: flex;  height: 100%; justify-content: center; align-items: center; flex-direction: column; gap: 4px; margin-top: 50px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/successIconPoppers.gif" style="width: 85px;">
                        <div style="font-size: 16px; font-weight: 600;">Payment Successfull!</div>
                        <div>Thanks for visiting MyMedicine</div>
                    </div>
            </div>
            <div class="bottomBar">
                <div @click="goToPage('commonHome')" style="display: flex; justify-content: center; align-items: center; padding: 8px; border: 1px solid #48acef; width: 30%; border-radius: 8px; cursor: pointer;">
                    <div style="color: #48acef;" >Home</div>
                </div>
                <div @click="goToPage('back')" style="display: flex; justify-content: center; align-items: center; padding: 8px; border: 1px solid #48acef; background: #48acef; width: 60%; border-radius: 8px; cursor: pointer;">
                    <div style="color: #fff;" >Back</div>
                </div>
            </div>   
        </div>
</template>

<script>
import axios from "axios";



export default {
    name: 'CommonSuccess',
    data: function() {
        return {
        };
    },
    computed: {
        
    },
    async mounted() {
    },

    methods: {

        goToPage(value) {
            if (value == 'BACK') {
                this.$router.go(-1);
            } else {
                this.$router.push({
                name: 'CustomerHomeScreen',
               });
            }
        },
    },
};

</script>
<style  scoped>
.TokenContainer {
    width: 150px;
    height: 44px;
    padding: 8px 8px 8px 16px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
    background: #1467BF1A;
}
.mobileContainer {
    margin: auto;
    width: 425px;
    background-color: white;
}
.bodyContainer {
    width: 100%;
    padding: 0 16px;
    height: 94vh;
    padding-bottom: 200px  !important;
    overflow: auto;
    background-color: white;
}
.bottomBar {
    position: absolute;
    bottom: 0;
    /* background: ; */
    box-shadow: 0px -4px 8px 0px #0000000A;
    width: 100%;
    max-width: 425px;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-evenly;
    z-index: 1;
    background: white;
}
.bottomSheetContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 70vh;
    background: #fff;
    border: 1px solid #ebebe8;
    border-radius: 16px;
    max-width: 425px;
    max-height: 70vh;
    overflow-y: scroll;
    z-index: 1000;
}
.doctorBodyContainer {
    padding: 32px 16px 32px 16px;
}
.experienceBox {
    padding: 8px 16px 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.bottomSheetHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8ebeb;
}
@media screen and (max-width: 425px){
.mobileContainer {
    width: 100%;
}
}
</style>